.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


.App-fullscreen {
    width: 100%;
    height: 100vh;
    min-height: 100%;
    margin: 0;
    padding: 0;
}

.App-loading {
  margin: auto; 
  position: absolute; 
  left: 4%; 
  right: 4%; 
  top: 25%; 
  bottom: 0%;
}

.App-canvas-graph {
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 0;
}
/* .App-hide {
  visibility: hidden;
} */

/* .o-hide:hover {
  display: block;
} */
